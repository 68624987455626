import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppMenuItems } from 'src/app/models/IPanelMenu/app-menu-items';
import { NavService } from '../../services/nav.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {
  public menuItems: AppMenuItems[];
  public url: any;

  constructor(
    private router: Router,
    private navServices: NavService,
    public elRef: ElementRef
  ) {
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter((items) => {
            if (items.RoutePath === event.url) {
              this.setNavActive(items);
            }
            if (!items.Children) {
              return false;
            }
            items.Children.filter((subItems) => {
              if (subItems.RoutePath === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.Children) {
                return false;
              }
              subItems.Children.filter((subSubItems) => {
                if (subSubItems.RoutePath === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  // Active NavBar State
  setNavActive(item): void {
    this.menuItems.filter((menuItem) => {
      if (menuItem !== item) {
        menuItem.active = false;
        document
          .querySelector('.main-body')
          .classList.remove('main-sidebar-show');
      }
      if (menuItem.Children && menuItem.Children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.Children) {
        menuItem.Children.filter((submenuItems) => {
          if (submenuItems.Children && submenuItems.Children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggleNavActive(item): void {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.Children) {
          return false;
        }
        a.Children.forEach((b) => {
          if (a.Children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  IsGranted(PerName: string): boolean{
    if(PerName !== undefined){
      return true;
    }else{
      return false;
    }
  }

  ngOnInit(): void {}
}
