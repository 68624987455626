import { Routes, RouterModule } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

// tslint:disable-next-line:variable-name
export const Full_Content_Routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../../modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'ecommerce',
    loadChildren: () =>
      import('../../modules/ecommerce/ecommerce.module').then(
        (m) => m.EcommerceModule
      ),
  },
  {
    path: 'ecommerceSetting',
    loadChildren: () =>
      import('../../modules/ecommerce-setting/ecommerce-setting.module').then(
        (m) => m.EcommerceSettingModule
      ),
  },
  {
    path: 'leads',
    loadChildren: () =>
      import('../../modules/leads/leads.module').then(
        (m) => m.LeadsModule
      ),
  },
  {
    path: 'accountManagement',
    loadChildren: () =>
      import('../../modules/account-management/account-management.module').then(
        (m) => m.AccountManagementModule
      ),
  },
  {
    path: 'administrator',
    loadChildren: () =>
      import('../../modules/administrator/administrator.module').then(
        (m) => m.AdministratorModule
      ),
  },
  {
    path: 'seo',
    loadChildren: () =>
      import('../../modules/seo/seo.module').then(
        (m) => m.SeoModule
      ),
  },

];
