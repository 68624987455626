
<ng-template #createAssetModal let-modal>
  <p-toast></p-toast>
  <!-- LARGE MODAL -->
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Large Modal</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #assetForm="ngForm">
      <div class="dropzone dropzone-primary" [dropzone]="config1" (init)="onUploadInit($event)"
        (onUploaded)="onFileSelected($event)" (uploadprogress)="onAddedFile($event)" (error)="onUploadError($event)"
        (success)="onUploadSuccess($event)">
        <div class="dz-message needsclick">
          <i class="fe fe-upload-cloud" aria-hidden="true"></i>
          <h6>Drop Multiple files here or click to upload multiple files.</h6>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button class="btn ripple btn-primary" type="button" (click)="saveAsset()">Save changes</button>
    <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
