import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AuthenticationApi, ConstApi, LookUpApi } from 'src/app/models/const-api';
import { UserDto } from '../../models/Sys/Users/user-dto';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ErrorWrapper, ResponseWrapper } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient) {}
  // User registration
  register(user: UserDto): Observable<any> {
    return this.httpClient.post(environment.APIUrl + 'auth/register', user);
  }

  // Login
  async signin(user: UserDto): Promise<any> {
    // return this.httpClient.post<any>(environment.APIUrl + 'auth/login', user);
    try {
      const Url = AuthenticationApi.login;
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await this.httpClient
        .post<any>(Url, {
          grant_type: 'password',
          client_id: '4',
          client_secret: 'ETIZb4nO6EuGrpNKHpT2xC788oZdpUj39s7W0kGR',
          email: user.email,
          password: user.password,
          scope: ''
        }, { headers })
        .toPromise();

      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  // Access user profile
  profileUser(): Observable<any> {
    return this.httpClient.get(environment.APIUrl + 'auth/user-profile');
  }
  async getCategoryDataByCategoryName(
    categoryName: string
  ): Promise<Observable<UserDto>> {
    try {
      const Url = environment.APIUrl;
      return new Observable<UserDto>(); /*this.httpClient.get<UserDto>(Url).pipe(
        map(() =>),
        catchError(this.httpError)
      );*/
    } catch (error: any) {
      const message = error.response ? error.response.error : error.response;
      throw this.httpError(message);
    }
  }
  httpError(error: {
    error: { message: string };
    status: any;
    message: any;
  }): any {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return throwError(msg);
  }
}
