import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoryDto, CreateOrEditCategoryDto } from 'src/app/models/Category/category-dto';
import { ConstApi } from 'src/app/models/const-api';
import { environment } from 'src/environments/environment';
import { TokenService } from '../Authentacation/token.service';
import { ErrorWrapper, ResponseWrapper } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private httpClient: HttpClient, private tokenService: TokenService) {}
  async getAllCategoryByIdLanguage(idLanguage: number): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = ConstApi.getAllCategoriesIdLanguage + idLanguage;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient.get<CategoryDto[]>(Url).toPromise();

      const data = {
        content: response,
        total: 0,
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
     const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
     throw new ErrorWrapper(error, message);
    }
  }
  async getSubCategoryByParentIdAndIdLanguage(parentId: number, idLanguage: number): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = ConstApi.getSubCategoryByParentIdAndIdLanguage + parentId + '/' + idLanguage;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient.get<CategoryDto[]>(Url).toPromise();

      const data = {
        content: response,
        total: 0,
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
     const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
     throw new ErrorWrapper(error, message);
    }
  }
  async GetAllCategory(): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = ConstApi.getAllCategory;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient.get<CategoryDto[]>(Url).toPromise();

      const data = {
        content: response,
        total: 0,
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
     const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
     throw new ErrorWrapper(error, message);
    }
  }
  public async CreateOrEditCategory(
    createOrEditCategoryDto: CreateOrEditCategoryDto
  ): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = ConstApi.createOrEditCategory;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .post<CreateOrEditCategoryDto>(Url, createOrEditCategoryDto)
        .toPromise();
      console.log(response);
      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      console.log(error);
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  public async showCategory(id: number): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = ConstApi.showCategory + id;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .get<CreateOrEditCategoryDto>(Url)
        .toPromise();
      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
