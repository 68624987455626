<!-- Sidemenu -->
<div class="main-sidebar main-sidebar-sticky side-menu">
    <div class="sidemenu-logo">
        <a class="main-logo" routerLink="/dashboard">
            <img src="../../assets/img/brand/logo-light.png" class="header-brand-img desktop-logo" alt="logo">
            <img src="../../assets/img/brand/icon-light.png" class="header-brand-img icon-logo" alt="logo">
            <img src="../../assets/img/brand/logo.png" class="header-brand-img desktop-logo theme-logo" alt="logo">
            <img src="../../assets/img/brand/icon.png" class="header-brand-img icon-logo theme-logo" alt="logo">
        </a>
    </div>
    <div class="main-sidebar-body">
        <ul class="nav">
            <!-- 1st Level Menu -->
            <ng-cotainer *ngFor="let menuItem of menuItems">
                <li class="nav-item" *ngIf="IsGranted(menuItem.PerName)"
                [ngClass]="{active: menuItem.active, 'show': menuItem.active}">
                <div class="nav-header" *ngIf="menuItem.HeadTitle">
                    <span class="nav-label">{{menuItem.HeadTitle}}</span>
                </div>
                <!-- has-Link -->
                <a class="nav-link" [routerLink]="!menuItem.ItemType ? null : [menuItem.RoutePath]" routerLinkActive="active"
                    *ngIf="menuItem.ItemType === 'link' " (click)="toggleNavActive(menuItem)">
                    <span class="shape1"></span>
                    <span class="shape2"></span>
                    <i class="{{menuItem.ItemIcon}} sidemenu-icon"></i>
                    <span class="sidemenu-label">{{menuItem.DisplayName}}</span>
                </a>
                <!-- has-Sub -->
                <a class="nav-link with-sub" [routerLink]="menuItem.ItemType ? null: [menuItem.RoutePath]"
                    routerLinkActive="active" *ngIf="menuItem.ItemType === 'sub'" (click)="toggleNavActive(menuItem)">
                    <span class="shape1"></span>
                    <span class="shape2"></span>
                    <i class="{{menuItem.ItemIcon}} sidemenu-icon"></i>
                    <span class="sidemenu-label">{{menuItem.DisplayName}}</span>
                    <span class="badge {{menuItem.BadgeClass}} side-badge"
                        *ngIf="menuItem.BadgeClass && menuItem.BadgeValue">
                        {{menuItem.BadgeValue}}
                    </span>
                    <i class="angle fe fe-chevron-right" [ngClass]="{ 'd-none': menuItem.BadgeClass}"></i>

                </a>

                <!-- 2nd Level menu -->
                <ul class="nav-sub" *ngIf="menuItem.Children">
                    <li class="nav-sub-item" *ngFor="let childrenItem of menuItem.Children"
                        [ngClass]="{'show': childrenItem.active}">

                        <!-- link -->
                        <a class="nav-sub-link" [routerLink]="!childrenItem.ItemType ? null : [childrenItem.RoutePath] "
                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            *ngIf="childrenItem.ItemType === 'link' " (click)="toggleNavActive(childrenItem)">
                            {{childrenItem.DisplayName}}
                        </a>

                        <!-- sub -->
                        <a class="nav-sub-link with-sub1" [routerLink]="childrenItem.ItemType ? null : [childrenItem.RoutePath]"
                             *ngIf="childrenItem.ItemType === 'sub' "
                            (click)="toggleNavActive(childrenItem)">
                            {{childrenItem.DisplayName}}
                            <i class="angle fe fe-chevron-right"></i>
                        </a>

                        <!-- 3rd Level menu -->
                        <ul class="nav-sub1" *ngIf="childrenItem.Children">
                            <li class="nav-sub-item1" *ngFor="let childrenSubItem of childrenItem.Children"
                            [ngClass]="{'show': childrenSubItem.active}">
                                <!-- link -->
                                <a class="nav-sub-link"
                                    [routerLink]="!childrenSubItem.ItemType ?  [childrenSubItem.RoutePath] : null"
                                    *ngIf="childrenSubItem.ItemType === 'link'" [routerLinkActiveOptions]="{exact : true}">
                                    {{childrenSubItem.DisplayName}}
                                </a>
                                <!-- sub -->
                                <a class="nav-sub-link with-sub2"
                                    [routerLink]="childrenSubItem.ItemType ? null : [childrenSubItem.RoutePath]"
                                    *ngIf="childrenSubItem.ItemType === 'sub' " (click)="toggleNavActive(childrenSubItem)">
                                    {{childrenSubItem.DisplayName}}
                                    <i class="angle fe fe-chevron-right"></i>
                                </a>

                                <!--4th level -->
                                <ul class="nav-sub2" *ngIf="childrenSubItem.Children">
                                    <li class="nav-sub-item2" *ngFor="let childrenSubItem1 of childrenSubItem.Children"
                                    [ngClass]="{'show': childrenSubItem1.active}">
                                        <a class="nav-sub-link" [routerLink]="!childrenSubItem1.ItemType ? [childrenSubItem1.RoutePath] : null "
                                        *ngIf="childrenSubItem1.ItemType === 'link'">
                                        {{childrenSubItem1.DisplayName}}</a>
                                    </li>
                                </ul>

                            </li>
                        </ul>

                    </li>
                </ul>
            </li>
            </ng-cotainer>
            

        </ul>


    </div>
</div>
<!-- End Sidemenu -->
