import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-flow-action',
  templateUrl: './work-flow-action.component.html',
  styleUrls: ['./work-flow-action.component.scss']
})
export class WorkFlowActionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
