import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }
   /*
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
   static _getStatusMessage(status: number): string {
    let message = '';
    switch (status) {
      case 200:
        message = 'All done. Request successfully executed';
        break;
      case 201:
        message = 'Data successfully created';
        break;
      case 400:
        message = 'Bad Request';
        break;
      case 401:
        message = 'Need auth';
        break;
      case 404:
        message = 'Not found';
        break;
      case 503:
        message = 'Service unavailable. Try again later';
        break;
      default:
        message = 'Something wrong. Client default error message';
        break;
    }
    return message;
  }


  static _getResponseErrorMessage(error: HttpErrorResponse): any {
    let errorMessage = '';
    if (error.error.error.message) {
      errorMessage = errorMessage + 'error message : ' + error.error.message + '\n';
    }
    if (error.statusText) {
      errorMessage = errorMessage + 'error status : ' + error.error.message + '\n';
    }
    if (error.message === 'Network Error') {
      errorMessage = errorMessage + 'error message : Oops! Network Error. Try again later \n';
    }
    else {
      errorMessage = errorMessage + 'error message : ' + error.message + '\n';
    }
    return errorMessage;
  }
}


/*
 * Create instant, which represent response object
 * @param {Object} [data] - custom data
 * @param {Object} [response] - axios response object
 * @param {String} [message] - custom message to display
*/
export class ResponseWrapper {
  data: {};
  success: any;
  status: number;
  statusMessage: string;
  message: string | null;

  constructor(response: any, data = {}, message: string = '') {
    this.data = data;
    this.success = response.success;
    this.status = response.status;
    this.statusMessage = UtilService._getStatusMessage(this.status);
    this.message = message || null;
  }
}



/*
 * Create instant, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
*/
export class ErrorWrapper extends Error {
  success: any;
  meta: any;
  code: any;
  status: any;
  statusMessage: string;

  constructor(error: any, message: string = '') {
    super();
    this.success = error.response ? error.response.data.success : false;
    this.meta = error.response ? error.response.data.meta : false;
    this.code = error.response ? error.response.data.code : false;
    this.status = error.response ? error.response.status : false;
    this.statusMessage = UtilService._getStatusMessage(this.status);
    this.message = message || UtilService._getResponseErrorMessage(error);
  }
}


/*
 * Uses to clear request data before send it
 * Client shouldn't change entity id
 * @param data
 * @return {{}}
*/
export function clearData(data: []): any {
  const result: any = {};
  data.forEach((item: any, propName: any) => {
    if (Array.isArray(item) && item.length) {
      result[propName] = item;
    }
    if (item && !Array.isArray(item) && (propName !== 'id')) {
      result[propName] = item;
    }
  });
  return result;
}

