<p-toast></p-toast>



<!-- Row -->
<div class="row row-sm">
  <div class="col-lg-12 col-md-12">
    <div class="card custom-card">
      <div class="card-body">
        <div>
          <h6 class="main-content-label mb-1">Create Project Type</h6>
          <p class="text-muted card-sub-title"></p>
        </div>
        <div id="wizard2">
          <form (ngSubmit)="onSubmit(projectTypeForm)" #projectTypeForm="ngForm" novalidate class="ecommerce-form action-buttons-fixed"
            enctype="multipart/form-data" method="post">

            <div class="card">
              <div class="p-grid p-fluid">

                <div class="p-col-12 p-md-4" *ngIf="_lookupCategory === 6 || _lookupCategory === 9 || _lookupCategory === 11">
                  <label for="categoryId">Parent Category <span class="tx-danger">*</span></label>
                  <ng-select class="form-control" [searchable]="true" #categoryId="ngModel" name="categoryId"
                    [ngClass]="{'is-invalid': categoryId.invalid }" [clearable]="false" id="categoryId" inputId="categoryId"
                    (change)="onChangeCategory(categoryId.value)" [(ngModel)]="CreateOrEditLookup.ParentId"
                    data-plugin-selectTwo title="Please select Parent Category" placeholder="-- Choose Parent Category --"
                    [autoDisplayFirst]="true">
                    <ng-option *ngFor="let parentCategory of parentCategoryData" [value]="parentCategory.id">
                     {{parentCategory.displayName}}</ng-option>
                  </ng-select>
                  <small id="categoryId-help">Please Choose Parent Category.</small>
                  <p-message *ngIf="categoryId.invalid" severity="error" text="This field is required">
                  </p-message>
                </div>

                <div class="p-col-12 p-md-4">
                  <label for="TitleEn">English Display Name <span class="tx-danger">*</span></label>
                  <input type="text" pInputText #TitleEn="ngModel" [(ngModel)]="CreateOrEditLookup.TitleEn" name="TitleEn" id="TitleEn" required
                    placeholder="Display Name in English"  [ngClass]="{'is-invalid': TitleEn.invalid }">
                  <small id="TitleEn-help">Display Name in English.</small>
                  <p-message *ngIf="TitleEn.invalid" severity="error" text="This field is required">
                  </p-message>
                </div>

                <div class="p-col-12 p-md-4">
                  <label for="TitleAr">Arabic Display Name <span class="tx-danger">*</span></label>
                  <input type="text" pInputText #TitleAr="ngModel" [(ngModel)]="CreateOrEditLookup.TitleAr" name="TitleAr" id="TitleAr" required
                    placeholder="Display Name in Arabic"  [ngClass]="{'is-invalid': TitleAr.invalid }">
                  <small id="TitleAr-help">Display Name in Arabic.</small>
                  <p-message *ngIf="TitleAr.invalid" severity="error" text="This field is required">
                  </p-message>
                </div>

                <div class="p-col-12 p-md-4">
                  <label for="DescriptionEn">English Description</label>
                  <input type="text" pInputText #DescriptionEn="ngModel" [(ngModel)]="CreateOrEditLookup.DescriptionEn" name="DescriptionEn" id="DescriptionEn"
                    placeholder="Description in English" >
                  <small id="DescriptionEn-help">Please enter Description in English.</small>

                </div>

                <div class="p-col-12 p-md-4">
                  <label for="DescriptionAr">Arabic Description</label>
                  <input type="text" pInputText #DescriptionAr="ngModel" [(ngModel)]="CreateOrEditLookup.DescriptionAr" name="DescriptionAr" id="DescriptionAr"
                   placeholder="Description in Arabic" >
                  <small id="DescriptionAr-help">Please enter Description in Arabic.</small>

                </div>

                <div class="p-col-12 p-md-4">
                  <label for="projectPurposeId">Project Order # <span class="tx-danger">*</span></label>
                  <input type="text" pInputText [(ngModel)]="CreateOrEditLookup.Order" #Order="ngModel" name="Order"
                    id="Order" required placeholder="eg.: 1" class="form-control"
                    [ngClass]="{'is-invalid': Order.invalid}">
                  <small id="projectPurposeId-help">Please Order Item.</small>
                  <p-message *ngIf="Order.invalid" severity="error" text="This field is required">
                  </p-message>
                </div>
              </div>
            </div>
            <button  class="btn btn-success pull-right" type="submit" >Submit</button>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- / Row -->
