import { Injectable } from '@angular/core';
import { AuthenticationApi } from 'src/app/models/const-api';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private issuer = {
    login: AuthenticationApi.login,
    register: AuthenticationApi.register,
  };
  constructor() {}
  handleData(token: any): void {
    localStorage.setItem('auth_token', token);
  }

  getToken(): any {
    return localStorage.getItem('auth_token');
  }
  // Verify the token
  isValidToken(): any {
    const token = this.getToken();

    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.issuer).indexOf(payload.iss) > -1
          ? true
          : false;
      }
    } else {
      return false;
    }
  }
  payload(token: any): any {
    const jwtPayload = token.split('.')[1];
    console.log(jwtPayload);
    return JSON.parse(atob(jwtPayload));
  }

  // User state based on valid token
  isLoggedIn(): boolean {
    return this.isValidToken();
  }

  // Remove token
  removeToken(): void{
    localStorage.removeItem('auth_token');
  }
}
