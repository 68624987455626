import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetsDto } from 'src/app/models/Assets/assets-dto';
import { CreateOrEditAssets } from 'src/app/models/Assets/create-or-edit-assets';
import { AssetsApi, ConstApi } from 'src/app/models/const-api';
import { environment } from 'src/environments/environment';
import { TokenService } from '../Authentacation/token.service';
import { ErrorWrapper, ResponseWrapper } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}

  public async CreateOrEditAssets(assetDto: FormData): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = ConstApi.CreateOrEditAssets;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .post<AssetsDto>(Url, assetDto)
        .toPromise();
      const data = {
        content: response,
        total: 0,
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  public async CreateOrEditAssetElement(formData: FormData): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = ConstApi.CreateOrEditAssetElement;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .post<AssetsDto>(Url, formData)
        .toPromise();
      const data = {
        content: response,
        total: 0,
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  public async EditAssetFileElement(formData: FormData): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = AssetsApi.EditAssetFileElement;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .post<boolean>(Url, formData)
        .toPromise();
      const data = {
        content: response,
        total: 0,
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  public async Show(id: number): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = AssetsApi.ShowAsset + id;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .get<AssetsDto>(Url)
        .toPromise();
      const data = {
        content: response,
        total: 0,
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  public async showAssetByElementId(projectId: number, ParentId: number): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = AssetsApi.showAssetByElementId + projectId + '/' + ParentId;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .get<AssetsDto>(Url)
        .toPromise();
      const data = {
        content: response,
        total: 0,
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  public async IsActiveAsset(
    id: number,
    active: boolean
  ): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = AssetsApi.IsActiveAsset;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .post<boolean>(Url, { assetId: id, isActive: active })
        .toPromise();
      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  public async IsArchiveAsset(
    id: number,
    active: boolean
  ): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = AssetsApi.IsArchiveAsset;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .post<boolean>(Url, { assetId: id, isArchive: active })
        .toPromise();
      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  public async IsDefaultAsset(
    id: number,
    active: boolean
  ): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url =  AssetsApi.IsDefaultAsset;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .post<boolean>(Url, { assetId: id, isDefaultImage: active })
        .toPromise();
      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  public async getAssetsByElementIdAndParentId(parentId: number, elementId: number): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = AssetsApi.getAssetsByElementIdAndParentId + parentId + '/' + elementId;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .get<AssetsDto>(Url)
        .toPromise();
      const data = {
        content: response,
        total: 0,
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
