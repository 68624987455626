import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CKEditor5 } from '@ckeditor/ckeditor5-angular';
@Component({
  selector: 'ceditor',
  templateUrl: './cktext-editor.component.html',
  styleUrls: ['./cktext-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CktextEditorComponent),
      multi: true,
    },
  ],
  
})
export class CktextEditorComponent implements OnInit,ControlValueAccessor{

  @Output() public ready = new EventEmitter<CKEditor5.Editor>();
  @Output() public WordCount = new EventEmitter<CKEditor5.Editor>();
  @Output() public CharacterCount = new EventEmitter<CKEditor5.Editor>();
  @Output() public autoSave = new EventEmitter();
  @Output() textChange: EventEmitter<any> = new EventEmitter<any>();
  contentLoad: boolean;
  public Editor = ClassicEditor;
  public editorConfig;
  public editorData = '<p>Hello, world!</p>';
  _text = '';
 
  @Input() set text(value: string) {
    this._text = value;
    this.textChange.emit(this._text);
  }
  get text(): string {
    return this._text;
  }
  constructor() { 
    
  }
  writeValue(value: any): void {
    if (value !== undefined && value != null) {
      this.text = value;
    } else {
      this.text = '';
    }
  }
  registerOnChange(fn: any): void {
    //throw new Error('Method not implemented.');
  }
  registerOnTouched(fn: any): void {
    //throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.ckTextEditorInit();
  }
  ckTextEditorInit(){
    this.editorConfig = {
      // readOnly: true,
      toolbar: {
        items: [
          'heading', '|',
          'alignment', '|',
          'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
          
          
          
          
          'strikethrough',
          'link', '|',
          'bulletedList', 'numberedList', 'todoList',
          '-', // break point
          'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
          'code', 'codeBlock', '|',
          'insertTable', '|',
          'outdent', 'indent', '|',
          'uploadImage', 'blockQuote', '|',
          'undo', 'redo'
        ],
        shouldNotGroupWhenFull: true
      },

      link: {
        // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
        addTargetToExternalLinks: true,
        defaultProtocol: 'http://',

      },
     
      licenseKey: '',
      allowedContent: false,
      image: {
        toolbar: ['fileExplorer'],
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
      },
      fontColor: {
        colors: [
          {
            color: 'hsl(0, 0%, 0%)',
            label: 'Black',
          },
          {
            color: 'hsl(0, 0%, 30%)',
            label: 'Dim grey',
          },
          {
            color: 'hsl(0, 0%, 60%)',
            label: 'Grey',
          },
          {
            color: 'hsl(0, 0%, 90%)',
            label: 'Light grey',
          },
          {
            color: 'hsl(0, 0%, 100%)',
            label: 'White',
            hasBorder: true,
          },
          {
            color: 'hsl(0, 75%, 60%)',
            label: 'Red',
          },
          {
            color: 'hsl(30, 75%, 60%)',
            label: 'Orange',
          },
          {
            color: 'hsl(60, 75%, 60%)',
            label: 'Yellow',
          },
          {
            color: 'hsl(90, 75%, 60%)',
            label: 'Light green',
          },
          {
            color: 'hsl(120, 75%, 60%)',
            label: 'Green',
          },
          {
            color: 'hsl(150, 75%, 60%)',
            label: 'Aquamarine',
          },
          {
            color: 'hsl(180, 75%, 60%)',
            label: 'Turquoise',
          },
          {
            color: 'hsl(210, 75%, 60%)',
            label: 'Light blue',
          },
          {
            color: 'hsl(240, 75%, 60%)',
            label: 'Blue',
          },
          {
            color: 'hsl(270, 75%, 60%)',
            label: 'Purple',
          },
        ],
      },
      fontSize: {
        options: [9, 11, 13, 'default', 17, 19, 21, 25],
        supportAllValues: true,
      },
      
      additionalLanguages: 'all',
      wordCount: {
        onUpdate: (stats) => {
          this.WordCount.emit(stats.words);
          this.CharacterCount.emit(stats.characters);
        },
        displayWords: true,
        displayCharacters: true,
      },
      isReadOnly: false,
      /*autotextConfig: {
        textConfig: currentLangAutoTexts,
      },*/
      
    };
    
  }

  public onReady(editor) {
    this.Editor = editor;
    console.log(this.Editor);
    //this.Editor.config = this.editorConfig;
    this.Editor.config.fontSize_defaultLabel = '30';
    this.ready.emit(editor);
  }

}
