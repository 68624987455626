
<ckeditor 
[editor]="Editor" (ready)="onReady($event)" 
[config]="editorConfig" [(ngModel)]="text" 
[required]="true" name="ckeditorBody"
[data]="editorData"
>

</ckeditor>

