<p-toast></p-toast>

<!-- Row -->
<div class="row row-sm">
  <div class="col-md-12 col-lg-12">
    <div class="card custom-card">
      <div class="card-header custom-card-header">
        <h6 class="main-content-label mb-0">Add New Asset</h6>
      </div>
      <div class="card-body">
        <div class="col-md-12">
          <form #assetForm="ngForm">
            <div class="card custom-card">
              <div class="p-0 ht-100p">
                <div class="product-grid">
                  <div class="dropzone dropzone-primary" [dropzone]="config1" (init)="onUploadInit($event)"
                    (onUploaded)="onFileSelected($event)" (error)="onUploadError($event)"
                    (success)="onUploadSuccess($event, 'add')" (removedFile)="onRemoveSuccess($event)">
                    <div class="dz-message needsclick">
                      <i class="fe fe-upload-cloud" aria-hidden="true"></i>
                      <h6>Drop Multiple files here or click to upload multiple files.</h6>
                    </div>
                  </div>


                </div>
              </div>
            </div>
            <button class="btn ripple btn-primary" type="button" (click)="saveAsset()">Save changes</button>


          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row row-sm">
  <div class="col-md-8 col-lg-9">
    <div class="row row-sm">
      <div class="col-md-6 col-lg-6 col-xl-4 col-sm-6" *ngFor="let asset of assets">
        <div class="card custom-card">
          <div class="p-0 ht-100p">
            <div class="product-grid">
              <div class="product-image">
                <a href="javascript:;" class="image">
                  <img class="pic-1" [alt]="asset.alt" [title]="asset.title" [src]="asset.assetUrl">
                </a>
                <a (click)="IsDefaultAsset(asset, asset.isDefaultImage)" class="product-like-icon active" href="javascript:;">
                  <i *ngIf="asset.isDefaultImage" class="fa fa-heart text-danger" aria-hidden="true"></i>
                  <i *ngIf="!asset.isDefaultImage" class="far fa-heart"></i>
                </a>
                <span class="product-discount-label"
                  [ngClass]="asset.MediaStatus.extensionData.class">{{asset.MediaStatus.Title}}</span>
                <div class="product-link">
                  <a (click)="selectAssetDetail(asset.id)" href="javascript:;">
                    <i class="fas fa-eye"></i>
                    <span>Quick View</span>
                  </a>
                  <a (click)="editAsset(asset.id)" href="javascript:;">
                    <i class="fas fa-edit"></i>
                    <span>Edit</span>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-md-4 col-lg-3 col-xl-3">
    <div class="card custom-card">
      <div class="card-body">
        <div class="row row-sm">
          <div class="col-sm-12">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search ...">
              <span class="input-group-append">
                <button class="btn ripple btn-primary" type="button">Search</button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-sm">
      <div class="col-md-12 col-lg-12">
        <div class="card custom-card">
          <div class="card-header custom-card-header">
            <h6 class="main-content-label mb-0">Asset Details</h6>
          </div>
          <div class="card-body">

            <table class="table mb-0 border-top table-bordered text-nowrap">
              <tbody *ngIf="selectedDetailAssets !== undefined">
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Physical Name</td>
                  <td>{{selectedDetailAssets.PhysicalName}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Display File Name</td>
                  <td>{{selectedDetailAssets.DisplayFileName}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Size</td>
                  <td>{{selectedDetailAssets.size}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">version</td>
                  <td>{{selectedDetailAssets.version}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">AssetType</td>
                  <td>{{selectedDetailAssets.AssetType.code}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Parent</td>
                  <td>{{selectedDetailAssets.Parent.name}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Extension</td>
                  <td>{{selectedDetailAssets.ExtensionData}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Creator User</td>
                  <td>{{selectedDetailAssets.User.fullname}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">isActive</td>
                  <td>
                    <div class="main-toggle main-toggle-success"
                      (click)="IsActiveAsset(selectedDetailAssets, selectedDetailAssets.isActive)"
                      [ngClass]="selectedDetailAssets.isActive ? 'on' : '' ">
                      <span></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">isDelete</td>
                  <td>
                    <div class="main-toggle main-toggle-success"
                      (click)="deleteAsset(selectedDetailAssets, selectedDetailAssets.isDelete)"
                      [ngClass]="selectedDetailAssets.isDelete ? 'on' : '' ">
                      <span></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">creation DateTime</td>
                  <td>{{selectedDetailAssets.createdAt | date: 'MM/dd/yyyy hh:mm:ss t'}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Last Update</td>
                  <td>{{selectedDetailAssets.updatedAt | date: 'MM/dd/yyyy hh:mm:ss t'}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Delete DateTime</td>
                  <td>{{selectedDetailAssets.deletedAt | date: 'MM/dd/yyyy hh:mm:ss t'}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Last Update User</td>
                  <td>{{selectedDetailAssets.LastModificationUser.fullname}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Delete User</td>
                  <td>{{selectedDetailAssets.DeleteUser.fullname}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Width</td>
                  <td>{{selectedDetailAssets.width}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Height</td>
                  <td>{{selectedDetailAssets.height}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Media Type</td>
                  <td></td>
                </tr>

                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Title</td>
                  <td>{{selectedDetailAssets.title}}</td>
                </tr>

                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Alt</td>
                  <td>{{selectedDetailAssets.alt}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Caption</td>
                  <td>{{selectedDetailAssets.caption}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Description</td>
                  <td>{{selectedDetailAssets.description}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">isDefaultImage</td>
                  <td>
                    <div class="main-toggle main-toggle-success"
                      (click)="IsDefaultAsset(selectedDetailAssets, selectedDetailAssets.isDefaultImage)"
                      [ngClass]="selectedDetailAssets.isDefaultImage ? 'on' : '' ">
                      <span></span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Media Status</td>
                  <td>{{selectedDetailAssets.MediaStatus.Title}}</td>
                </tr>
                <tr>
                  <td scope="row" class="text-primary m-b-5 tx-14">Order</td>
                  <td>{{selectedDetailAssets.order}}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Row -->

<ng-template #createAssetModal let-modal>
  <p-toast></p-toast>
  <!-- LARGE MODAL -->
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Edit Asset: </h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #assetForm="ngForm">
      <div class="row">
        <div class="col-md-12">
          <div class="dropzone dropzone-primary" [dropzone]="Editconfig1" (init)="onUploadInit($event)"
            (onUploaded)="onFileSelected($event)" (error)="onUploadError($event)"
            (success)="onUploadSuccess($event,'edit')" (removedFile)="onRemoveSuccess($event)"
            (canceled)="onRemoveSuccess($event)">
            <div class="dz-message needsclick">
              <i class="fe fe-upload-cloud" aria-hidden="true"></i>
              <h6>Drop file here or click to upload.</h6>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="selectedAssetForEdit">
          <div class="multi-collapse mt-2">
            <div class="card card-body">
              <div class="card custom-card">
                <div class="p-0 ht-100p">
                  <div class="product-grid">
                    <div class="product-image">
                      <a href="javascript:;" class="image" >
                        <img class="pic-1" [alt]="selectedAssetForEdit.alt"
                          [title]="selectedAssetForEdit.title" [src]="selectedAssetForEdit.assetUrl">
                      </a>
                      <a class="product-like-icon" href="javascript:;">
                        <i class="far fa-heart"></i>

                      </a>

                      <span class="product-discount-label"
                        [ngClass]="selectedAssetForEdit.MediaStatus.extensionData.class">{{selectedAssetForEdit.MediaStatus.Title}}</span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="selectedAssetForEdit">
          <div class="multi-collapse mt-2">
            <div class="card card-body">
              <table class="table mb-0 border-top table-bordered text-nowrap">
                <tbody *ngIf="selectedAssetForEdit !== undefined">
                  <tr>
                    <td scope="row" class="text-primary m-b-5 tx-14">isActive</td>
                    <td>
                      <div class="main-toggle main-toggle-success"
                        (click)="IsActiveAsset(selectedAssetForEdit, selectedAssetForEdit.isActive)"
                        [ngClass]="selectedAssetForEdit.isActive ? 'on' : '' ">
                        <span></span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" class="text-primary m-b-5 tx-14">isDelete</td>
                    <td>
                      <div class="main-toggle main-toggle-success"
                        (click)="deleteAsset(selectedAssetForEdit, selectedAssetForEdit.isDelete)"
                        [ngClass]="selectedAssetForEdit.isDelete ? 'on' : '' ">
                        <span></span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" class="text-primary m-b-5 tx-14">isDefaultImage</td>
                    <td>
                      <div class="main-toggle main-toggle-success"
                        (click)="IsDefaultAsset(selectedAssetForEdit, selectedAssetForEdit.isDefaultImage)"
                        [ngClass]="selectedAssetForEdit.isDefaultImage ? 'on' : '' ">
                        <span></span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" class="text-primary m-b-5 tx-14">Last Update</td>
                    <td>{{selectedAssetForEdit.updatedAt | date: 'MM/dd/yyyy hh:mm:ss'}}</td>
                  </tr>

                  <tr>
                    <td scope="row" class="text-primary m-b-5 tx-14">Title</td>
                    <td>
                      <input type="text" class="form-control" [value]="createOrEditAssets.title"
                        name="title" placeholder="Title" [(ngModel)]="createOrEditAssets.title">
                    </td>
                  </tr>

                  <tr>
                    <td scope="row" class="text-primary m-b-5 tx-14">Alt</td>
                    <td>
                      <input type="text" class="form-control" [value]="createOrEditAssets.alt"
                        name="alt" placeholder="Alt" [(ngModel)]="createOrEditAssets.alt">
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" class="text-primary m-b-5 tx-14">Caption</td>
                    <td>
                      <input type="text" class="form-control" [value]="createOrEditAssets.caption"
                        name="caption" placeholder="Caption" [(ngModel)]="createOrEditAssets.caption">
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" class="text-primary m-b-5 tx-14">Description</td>
                    <td>
                      <input type="text" class="form-control" [value]="createOrEditAssets.description"
                        name="description" placeholder="Description" [(ngModel)]="createOrEditAssets.description">
                    </td>
                  </tr>

                  <tr>
                    <td scope="row" class="text-primary m-b-5 tx-14">Media Status</td>
                    <td>
                      <ng-select class="form-control" [searchable]="true" #lookupItemId="ngModel" name="lookupItemId"
                        required [ngClass]="{'is-invalid': lookupItemId.invalid }" [clearable]="false" id="lookupItemId"

                        [(ngModel)]="createOrEditAssets.assetStatusId" title="Please select Lookup Item"
                        placeholder="-- Choose lookupItem --" [autoDisplayFirst]="true">

                        <ng-option *ngFor="let lookupItem of lookupItems" [value]="lookupItem.id">
                          {{lookupItem.Title}}</ng-option>
                      </ng-select>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row" class="text-primary m-b-5 tx-14">Order</td>
                    <td>
                      <input type="text" class="form-control" [value]="createOrEditAssets.order"
                        name="order" placeholder="Order" [(ngModel)]="createOrEditAssets.order">
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <button class="btn ripple btn-primary" type="submit" (click)="EditAssetElement()">Save changes</button>
    </form>
  </div>
  <div class="modal-footer">

    <button type="button" class="btn ripple btn-secondary" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
