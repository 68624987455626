import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetsConst } from '../models/assets-const';
import { UserDto } from '../models/Sys/Users/user-dto';
import { AuthService } from '../services/Authentacation/auth.service';
import { AuthStateService } from '../services/Authentacation/AuthState/auth-state.service';
import { TokenService } from '../services/Authentacation/token.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  AssetLogo = AssetsConst.defaultImage;
  errors = null;
  user: UserDto = new UserDto();
  public submitted = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    private token: TokenService,
    private authState: AuthStateService
  ) { }

  ngOnInit(): void {
    this.Submit();
  }

  Submit(): void{
    this.authService.signin(this.user).then(
      (result) => {
        console.log(result.data.content);
        this.responseHandler(result.data.content);
        this.authState.setAuthState(true);
        // this.user.reset();
        this.router.navigate(['/dashboard']);
      },
      (error) => {
        this.errors = error.error;
      },
    );
  }
  // Handle response
  responseHandler(data: any): void{
    this.token.handleData(data.access_token);
  }
}
