import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '../../Authentacation/token.service';
import { UserPermissionApi } from 'src/app/models/const-api';
import { ResponseWrapper, ErrorWrapper } from '../../util/util.service';
import { UserResponse } from 'src/app/response/user-response';
@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService
  ) {}
  public async UserRolePermission(): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = UserPermissionApi.UserRolePermission;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      console.log(headers);
      const response = await this.httpClient.post<UserResponse>(Url,{}, { headers }).toPromise();
      console.log(response);
      return new ResponseWrapper(response, response.data, response.message);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusMessage;
      throw new ErrorWrapper(error, message);
    }
  }
}
