<div class="col-lg-12 col-md-12">
  <div class="card custom-card">
      <div class="card-body">
          <div>
              <h6 class="main-content-label mb-1">Search</h6>
              <p class="text-muted card-sub-title">Click the buttons below to show and hide another element via class changes</p>
          </div>
          <div>
              <a class="btn ripple btn-primary" href="javascript:;" role="button" (click)="toggle()">Toggle Content</a>
              <div class="mg-t-5" id="collapseExample" *ngIf="isCollapsed">
                  <div class="card card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident.
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
