import { LookupItemsDetail } from '../Lookup/lookup-item-dto';
import { UserDto } from '../Sys/Users/user-dto';

export class AssetsDto {
  id!: number;
  PhysicalName!: string;
  DisplayFileName!: string;
  size!: string;
  version!: number;
  RelativeUrl!: string;
  StorageDeviceId!: number;
  AssetTypeId!: number;
  ParentId!: number;
  ExtensionData!: string;
  userId!: number;
  isActive!: boolean;
  isDelete!: boolean;
  createdAt!: string;
  updatedAt!: string;
  deletedAt!: string;
  LastModificationUserId!: number;
  DeleteUserId!: number;
  width!: string;
  height!: string;
  elementId!: number;
  title!: string;
  alt!: string;
  caption!: string;
  description!: string;
  assetId!: string;
  status!: boolean;
  isDefaultImage!: boolean;
  assetStatusId!: number;
  order!: number;
  Parent!: ParentDto;
  StorageDevice!: StorageDeviceDto;
  AssetType!: AssetTypeDto;
  User!: UserDto;
  LastModificationUser!: UserDto;
  DeleteUser!: UserDto;
  assetUrl!: string;
  MediaStatus!: LookupItemsDetail;

}

export class ParentDto{
  id!: number;
  name!: string;
}
export class StorageDeviceDto{
  id!: number;
  name!: string;
}
export class AssetTypeDto{
  id!: number;
  code!: string;
}

