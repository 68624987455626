import { CategoryDto } from '../Category/category-dto';

export class CreateOrEditLookupDto {
  id!: number;
  languageId!: number;
  LookupCategoryId!: number;
  ParentId!: number;
  Order!: number;
  TitleEn!: string;
  TitleAr!: string;
  DescriptionEn!: string;
  DescriptionAr!: string;
}
export class LookupItemsDto {
  ProjectStyle!: LookupItemsDetail[];
  ProjectType!: LookupItemsDetail[];
  ProjectPurpose!: LookupItemsDetail[];
  ProjectStatus!: LookupItemsDetail[];
  MeasurementType!: LookupItemsDetail[];
  Country!: LookupItemsDetail[];
  State!: LookupItemsDetail[];
  ProjectCommunity!: LookupItemsDetail[];
}

export class LookupItemsDetail {
  id!: number;
  LookupCategoryId!: number;
  ParentId!: number;
  Order!: number;
  Code!: string;
  Title!: string;
  Description!: string;
  extensionData!: JSON;
}
export class GetAllLookupItemDto {
  id!: number;
  LookupCategoryId!: number;
  Order!: number;
  Code!: string;
  TitleEn!: string;
  TitleAr!: string;
  DescriptionEn!: string;
  DescriptionAr!: string;
  isActive: boolean;
  isArchive: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  ProjectCategory!: CategoryDto;
}

export enum LookupCategoriesEnum {
  AccountType = 1,
  Country = 2,
  State = 3,
  Community = 4,
  MeasurementType = 5,
  ProjectStyle = 6,
  ProjectContentType = 7,
  ProjectFloors = 8,
  ProjectPurpose = 9,
  SVECOffice = 10,
  ProjectType = 11,
  SocialMediaType = 12,
  SvecPagesFilter = 13,
  ProjectStatus = 14,
  ProjectCommunity = 15,
  ItemWorkFlowState = 16,
  OfferStyle = 17,
  OfferType = 18,
  OfferPurpose = 19,
  ParentCategory = 20,
  OfferContentType = 21,
  LeadsStatus = 22,
  UserStatus = 23,
  AssetStatus = 24,
  OfferCategory = 25,
  ArticleStatus = 26,
  ArticleCategory = 27,
}
export enum ProjectCategoryDataEnum {
  ProjectContentType = 1,
  ProjectFloor = 2,
  ProjectSocial = 3,
  OfferContentType = 4,
}
