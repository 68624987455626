export class CreateOrEditAssets {
  id!: number;
  files!: File[];
  elementId!: number;
  parentId!: number;
  title!: string;
  alt!: string;
  caption!: string;
  description!: string;
  assetStatusId!: number;
  order!: number;
}
