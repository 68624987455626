import { Injector } from '@angular/core';
import { ConstApi } from 'src/app/models/const-api';
import { AppMenuItems } from 'src/app/models/IPanelMenu/app-menu-items';
import { AppConsts } from '../AppConsts';
import { Location } from '@angular/common';
import { UserPermissionService } from 'src/app/services/Sys/UserPermission/user-permission.service';

export abstract class AppComponentBase {
  //localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
  currentLang: string;
  private location: Location;
  private userPermissionService: UserPermissionService;

  routeCheckUrl = false;
  title = '';
  constructor(injector: Injector) {
    this.location = injector.get(Location);
    this.userPermissionService = injector.get(UserPermissionService);
    if (this.location.path() === '') {
      this.routeCheckUrl = false;
    } else {
      this.routeCheckUrl = true;
    }
  }
  getEnterpriseLogo(): string {
    return ConstApi.iLogo;
  }
  getNavPages(): Array<AppMenuItems> {
    let MENUITEMS: Array<AppMenuItems> = [
      // title
      { HeadTitle: 'dashboard', PerName: '' },
      {
        RoutePath: '/dashboard',
        PerName: '',
        DisplayName: 'Dashboard',
        ItemType: 'link',
        ItemIcon: 'ti-home',
        active: true,
      },
      { HeadTitle: 'Ecommerce', PerName: 'Ecommerce' },
      {
        DisplayName: 'Ecommerce',
        ItemIcon: 'ti-harddrives',
        ItemType: 'sub',
        active: false,
        PerName: 'Ecommerce',
        Children: [
          {
            RoutePath: '/ecommerce/category',
            ItemIcon: 'ti-harddrives',
            DisplayName: 'Category',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerce/projects',
            DisplayName: 'Project',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerce/projectFloor',
            DisplayName: 'Project Floors',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerce/projectContentType',
            DisplayName: 'Project Content Type',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerce/projectType',
            DisplayName: 'Project Type',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerce/projectStyle',
            DisplayName: 'Project Style',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerce/projectPurpose',
            DisplayName: 'Project Purpose',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerce/Tags',
            DisplayName: 'Project Tags',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerce/offers',
            DisplayName: 'offers',
            ItemType: 'link',
          },
        ],
      },
      { HeadTitle: 'Ecommerce Setting', PerName: 'EcommerceSetting' },
      {
        DisplayName: 'Ecommerce Setting',
        ItemIcon: 'ti-harddrives',
        ItemType: 'sub',
        active: false,
        PerName: 'EcommerceSetting',
        Children: [
          {
            RoutePath: '/ecommerceSetting/country',
            DisplayName: 'Country',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerceSetting/city',
            DisplayName: 'City',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerceSetting/offerCategory',
            DisplayName: 'Offer Category',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerceSetting/measurement',
            DisplayName: 'Measurement',
            ItemType: 'link',
          },
          {
            RoutePath: '/ecommerceSetting/SvecFilter',
            DisplayName: 'Svec Filter Pages',
            ItemType: 'link',
          },
        ],
      },
      { HeadTitle: 'SEO Marketing', PerName: 'Marketing' },
      {
        DisplayName: 'SEO Marketing',
        ItemIcon: 'ti-harddrives',
        ItemType: 'sub',
        active: false,
        PerName: 'Marketing',
        Children: [
          { RoutePath: '/seo/pages', DisplayName: 'Pages', ItemType: 'link' },
          {
            RoutePath: '/seo/articleCategory',
            DisplayName: 'ِArticle Category',
            ItemType: 'link',
          },
          {
            RoutePath: '/seo/articles',
            DisplayName: 'ِArticles',
            ItemType: 'link',
          },
          //{ RoutePath: '/seo/faqs', DisplayName: 'Faqs', ItemType: 'link' },
          // { RoutePath: '/seo/mail', DisplayName: 'Email Marketing', ItemType: 'link' },
        ],
      },
      /*{
          title: 'Account Management', icon: 'ti-harddrives', type: 'sub', active: false,
          children: [
            { RoutePath: '/users', DisplayName: 'Users', type: 'link' },
            { RoutePath: '/account', DisplayName: 'Account', type: 'link' },
          ]
        },
        { HeadTitle: 'Administrator' },
        {
          DisplayName: 'Administrator', ItemIcon: 'ti-harddrives', ItemType: 'sub', active: false,
          Children: [
            { RoutePath: '/administrator/Organization Staff', DisplayName: 'Staff', ItemType: 'link' },
            { RoutePath: '/administrator/users', DisplayName: 'Users', ItemType: 'link' },
            { RoutePath: '/administrator/Notification Template', DisplayName: 'Notififcation Templates', ItemType: 'link' },
            { RoutePath: '/administrator/Notification Type', DisplayName: 'Notification Type', ItemType: 'link' },
            { RoutePath: '/administrator/Notififcation Queue', DisplayName: 'Notififcation Queue', ItemType: 'link' },
            { RoutePath: '/administrator/Users', DisplayName: 'Notififcation Queue', ItemType: 'link' },
          ]
        },*/
      /*{
          title: 'Marketing', icon: 'ti-harddrives', type: 'sub', active: false,
          children: [
            { path: '/marketing/seo', DisplayName: 'SEO', type: 'link' },
            { path: '/marketing/email', DisplayName: 'Email', type: 'link' },
          ]
        },*/
    ];
    this.getUserPermission();
    return MENUITEMS;
  }
  getUserPermission(): any {
    this.userPermissionService.UserRolePermission().then((res) => {
      console.log(res);
    });
  }
}
