import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { CategoryDto } from 'src/app/models/Category/category-dto';
import { LanguageDto } from 'src/app/models/Language/language-dto';
import { CreateOrEditLookupDto, LookupCategoriesEnum } from 'src/app/models/Lookup/lookup-item-dto';
import { CategoryService } from 'src/app/services/Category/category.service';
import { LanguageService } from 'src/app/services/Language/language.service';
import { LookupItemsService } from 'src/app/services/Lookup/lookup-items.service';

@Component({
  selector: 'app-create-or-edit-lookup',
  templateUrl: './create-or-edit-lookup.component.html',
  styleUrls: ['./create-or-edit-lookup.component.scss']
})
export class CreateOrEditLookupComponent implements OnInit {
  submitted = true;
  contentLoad = false;
  languageData: LanguageDto[] = [];
  languageId!: number;
  _lookupItemId!: number;
  get LookupItemId(): number {
    return this._lookupItemId;
  }
  @Input() set LookupItemId(value: number) {
    if (value !== undefined){
      this._lookupItemId = value;
      this.CreateOrEditLookup.id = this._lookupItemId;
      this.getLookupItemById(this._lookupItemId);
    }
  }
  CreateOrEditLookup: CreateOrEditLookupDto =
    new CreateOrEditLookupDto();
  parentCategoryData: CategoryDto[] = [];
   // tslint:disable-next-line:variable-name
   _lookupCategory!: LookupCategoriesEnum;
   get LookupCategory(): LookupCategoriesEnum {
     return this._lookupCategory;
   }
   @Input() set LookupCategory(value: LookupCategoriesEnum) {
     if (value !== undefined){
       this._lookupCategory = value;
       console.log(this._lookupCategory);
     }
   }
  constructor(
    private categoryService: CategoryService,
    private lookupService: LookupItemsService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private e1: ElementRef,
    private primengConfig: PrimeNGConfig,
    private router: Router
  ) {}

  ngOnInit(): void {
    /*this.route.params.subscribe((params: Params) => {
      this.lookupItemId = params.id; // log the value of id
      this.CreateOrEditLookup.id =  this.lookupItemId;
    });
    if (this._lookupItemId !== undefined) {
       this.getLookupItemById(this._lookupItemId);
    }*/
    this.getAllCategoryByIdLanguage(1);
  }
  onSubmit(event: any): void {
    this.submitted = true;
    this.CreateOrEditLookupItem();
    console.log(this.submitted);
  }
  onChangeCategory(event: any): void {
    if (event !== undefined) {
      const categoryId = event;
      this.CreateOrEditLookup.ParentId = categoryId;
      this.validForm();
    }
  }
  onChangeDisplayName(event: any): void {
    if (event !== undefined) {
      const displayName = event;
      this.CreateOrEditLookup.TitleEn = displayName;
      this.validForm();
    }
  }
  getAllCategoryByIdLanguage(languageId: any): void {
    this.categoryService.getAllCategoryByIdLanguage(languageId).then((res) => {
      const vres = res.data.content.data;
      this.parentCategoryData = vres;
    });
  }
  getLookupItemById(lookupItemId: number): void {
    this.lookupService.getLookupItemById(lookupItemId).then((res) => {
      const vres = res.data.content.data;
      this.CreateOrEditLookup = vres[0];
      console.log(this.CreateOrEditLookup);
    });
  }
  CreateOrEditLookupItem(): void {
    if (this.CreateOrEditLookup.id === undefined) {
      this.CreateOrEditLookup.id = null;
    }
    this.CreateOrEditLookup.LookupCategoryId = this._lookupCategory;
    console.log(this.CreateOrEditLookup);
    
    this.lookupService
      .createOrEditLookupItem(this.CreateOrEditLookup)
      .then((res) => {
        const vres: boolean = res.data.content.data;
        console.log(vres);
        if (vres) {
          this.showSuccess('Successfully Submit Data');
          //this.View();
        }else{
          this.showError('Failed to Submit Data');
        }
      });
  }
  validForm(): void {
    if (
      this.CreateOrEditLookup.languageId !== undefined &&
      this.CreateOrEditLookup.ParentId !== undefined &&
      this.CreateOrEditLookup.TitleEn !== undefined
    ) {
      this.submitted = false;
    }
  }
  showSuccess(message: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message,
    });
  }
  showError(message: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
    });
  }
  View(): void {
    this.router.navigate(['ecommerce/projectType']);
  }

}
