import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LookUpApi } from 'src/app/models/const-api';
import { CreateOrEditLookupDto, GetAllLookupItemDto, LookupItemsDetail, LookupItemsDto } from 'src/app/models/Lookup/lookup-item-dto';
import { environment } from 'src/environments/environment';
import { TokenService } from '../Authentacation/token.service';
import { ErrorWrapper, ResponseWrapper } from '../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class LookupItemsService {

  constructor(private httpClient: HttpClient, private tokenService: TokenService) { }

  public async getLookUpItem(parentId: number, languageId: number): Promise<any> {
    try {
      const Url = LookUpApi.getLookUpItem + parentId + '/' + languageId;
      const response = await this.httpClient
        .get<LookupItemsDto>(Url)
        .toPromise();
      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  async getLookUpItemByLookupCategoryId(LookupCategoryId: number, languageId: number): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = LookUpApi.getLookUpItemByLookupCategoryId + LookupCategoryId + '/' + languageId;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .get<LookupItemsDetail[]>(Url)
        .toPromise();

      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  async getAllLookupItem(LookupCategoryId: number): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url =
        LookUpApi.getAllLookupItem + LookupCategoryId;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .get<GetAllLookupItemDto[]>(Url)
        .toPromise();

      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  async getLookupItemById(Id: number): Promise<any>{
    try {
      const token = this.tokenService.getToken();
      const Url = LookUpApi.getAllLookupItemById + Id;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .get<GetAllLookupItemDto[]>(Url)
        .toPromise();

      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
  public async createOrEditLookupItem(
    createOrEditLookupDto: CreateOrEditLookupDto
  ): Promise<any> {
    try {
      const token = this.tokenService.getToken();
      const Url = LookUpApi.createOrEditLookupItem;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      };
      const response = await this.httpClient
        .post<boolean>(Url, {lookupItemData: createOrEditLookupDto})
        .toPromise();
      console.log(response);
      const data = {
        content: response,
        total: Number(response),
      };
      return new ResponseWrapper(response, data);
    } catch (error) {
      console.log(error);
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
