import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-column-function',
  templateUrl: './column-function.component.html',
  styleUrls: ['./column-function.component.scss']
})
export class ColumnFunctionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
