<!-- Row -->

<div class="row row-sm">
  <div class="col-lg-12">
    <div class="card custom-card overflow-hidden">
      <div class="card-body">
        <div>
          <h6 class="main-content-label mb-1">Project Data</h6>
          <p class="text-muted card-sub-title">Searching, ordering and paging goodness will be immediately
            added to the table, as shown in this example.</p>
        </div>

        <p-table #dt1 [value]="lookupItems" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10,25,50]" [loading]="loading" styleClass="p-datatable-customers p-datatable-gridlines"
          [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [globalFilterFields]="['name','country.name','representative.name','status']" [columns]="headerColumns">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
                (click)="clear(dt1)"></button>
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search keyword" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{col.header}}
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-lookupItems>
            <tr>
              <td>
                <span *ngIf="lookupItems.ParentId == 0">
                  Offer
                </span>
                <span *ngIf="lookupItems.ParentId != 0">
                  {{lookupItems.ProjectCategory.displayName}}
                </span>

              </td>
              <td>
                {{lookupItems.TitleEn}}
              </td>
              <td>
                {{lookupItems.TitleAr}}
              </td>
              <td>

              </td>
              <td>
                <div class="main-toggle main-toggle-success" (click)="clickEvent2()"
                  [ngClass]="lookupItems.isActive ? 'on' : '' ">
                  <span></span>
                </div>
              </td>
              <td>
                <div class="main-toggle main-toggle-success" (click)="clickEvent2()"
                  [ngClass]="lookupItems.isArchive ? 'on' : '' ">
                  <span></span>
                </div>
              </td>
              <td>{{lookupItems.createdAt | date: 'MM/dd/yyyy'}}</td>
              <td>{{lookupItems.updatedAt | date: 'MM/dd/yyyy'}}</td>
              <td>{{lookupItems.deletedAt | date: 'MM/dd/yyyy'}}</td>
              <td>
                <span ngbDropdown placement="bottom-center">
                  <button class="btn ripple btn-primary" ngbDropdownToggle>
                    <i class="pi pi-cog"></i>

                  </button>
                  <div ngbDropdownMenu>
                    <!--<a class="dropdown-item" href="javascript:;"><i class="fas fa-external-link-alt"></i> Preview</a>-->
                    
                    <a class="dropdown-item" (click)="edit(lookupItems.id,largeDescmodal)" href="javascript:;"><i class="fas fa-edit"></i>
                      Edit</a>
                  </div>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="9">No customers found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<!-- End Row -->
<ng-template #largeDescmodal let-modal>
  <!-- LARGE MODAL -->

  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Large Modal</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-create-or-edit-lookup [LookupCategory]="lookupCategory" [LookupItemId]="LookupItemId"></app-create-or-edit-lookup>
  
  </div>
  <div class="modal-footer">
    <button type="button" class="btn ripple btn-secondary" (click)="close()">Close</button>
  </div>
</ng-template>