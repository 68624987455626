import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appProjectRefValidation]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ProjectRefValidationDirective,
    multi: true
  }]
})
export class ProjectRefValidationDirective {

  constructor() { }
  validate(control: AbstractControl): {[key: string]: any} | null {
    if (control.value && control.value.length !== 10) {
      return { projectRefInvalid: true };
    }
    return null;
  }

}
