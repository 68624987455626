import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import {
  MessageService,
  ConfirmationService,
  PrimeNGConfig,
} from 'primeng/api';
import { AssetsDto } from 'src/app/models/Assets/assets-dto';
import { CreateOrEditAssets } from 'src/app/models/Assets/create-or-edit-assets';
import {
  GetAllLookupItemDto,
  LookupCategoriesEnum,
} from 'src/app/models/Lookup/lookup-item-dto';
import { CreateAssetModalComponent } from 'src/app/modules/ecommerce/projects/create-or-edit-project/create-asset-modal/create-asset-modal.component';
import { AssetsService } from 'src/app/services/Assets/assets.service';
import { LookupItemsService } from 'src/app/services/Lookup/lookup-items.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-asset-widget',
  templateUrl: './asset-widget.component.html',
  styleUrls: ['./asset-widget.component.scss'],
})
export class AssetWidgetComponent implements OnInit {
  @ViewChild('createAssetModal', { static: false })
  createAssetModal: CreateAssetModalComponent;
  // tslint:disable-next-line:variable-name
  _parentId!: number;
  // tslint:disable-next-line:variable-name
  _elementId!: number;
  @Input()
  set ParentId(value: number) {
    if (value !== undefined) {
      this._parentId = value;
      this.getAssetsByElementIdAndParentId(this._parentId, this._elementId);
    }
  }
  get ParentId(): number {
    return this._parentId;
  }

  @Input()
  set ElementId(value: number) {
    if (value !== undefined) {
      this._elementId = value;
      this.getAssetsByElementIdAndParentId(this._parentId, this._elementId);
    }
  }
  get ElementId(): number {
    return this._elementId;
  }
  lookupCategory: LookupCategoriesEnum = LookupCategoriesEnum.AssetStatus;
  lookupItems!: GetAllLookupItemDto[];
  assets: AssetsDto[] = [];
  singleAsset!: AssetsDto;
  selectedDetailAssets: AssetsDto;
  selectedAssetForEdit: AssetsDto;
  // selectedFile: File;
  createOrEditAssets: CreateOrEditAssets = new CreateOrEditAssets();
  asset: AssetsDto = new AssetsDto();
  selectedAssets: AssetsDto[] = [];
  submitted = false;
  public config1: DropzoneConfigInterface = {
    clickable: true,
    addRemoveLinks: true,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    acceptedFiles: '.png, .jpg, .jpeg, .webp, .svg',
    url: environment.APIUrl + 'v1/DraftAssets',
    maxFiles: 5,
  };
  public Editconfig1: DropzoneConfigInterface = {
    clickable: true,
    addRemoveLinks: true,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
    maxFiles: 1,
    uploadMultiple: false,
    url: environment.APIUrl + 'v1/DraftAssets',
  };
  assetHeaderColumns!: any[];
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private assetsService: AssetsService,
    private e1: ElementRef,
    private primengConfig: PrimeNGConfig,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private lookupItemsService: LookupItemsService
  ) {
    this.createOrEditAssets.files = [];
  }
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this._elementId = params.id; // log the value of id
    });
    this.GetAllLookup();
  }
  GetAllLookup(): void {
    this.lookupItemsService
      .getLookUpItemByLookupCategoryId(this.lookupCategory, 1)
      .then((res) => {
        this.lookupItems = res.data.content.data;
      });
  }

  showAssetById(): void {}

  getAssetsByElementIdAndParentId(parentId: number, elementId: number): void {
    if (parentId !== undefined && elementId !== undefined) {
      this.assetsService
        .getAssetsByElementIdAndParentId(parentId, elementId)
        .then((res) => {
          this.assets = res.data.content.data;
        });
    }
  }
  selectAssetDetail(assetPId): void {
    this.selectedDetailAssets = this.assets.filter((c) => c.id === assetPId)[0];
  }
  editAsset(assetPId): void {
    this.selectedAssetForEdit = this.assets.filter((c) => c.id === assetPId)[0];
    this.createOrEditAssets.elementId = this._elementId;
    this.createOrEditAssets.parentId = this._parentId;
    this.createOrEditAssets.id = this.selectedAssetForEdit.id;
    this.createOrEditAssets.title = this.selectedAssetForEdit.title;
    this.createOrEditAssets.alt = this.selectedAssetForEdit.alt;
    this.createOrEditAssets.caption = this.selectedAssetForEdit.caption;
    this.createOrEditAssets.description = this.selectedAssetForEdit.description;
    this.createOrEditAssets.assetStatusId =
      this.selectedAssetForEdit.assetStatusId;
    this.createOrEditAssets.order = this.selectedAssetForEdit.order;
    this.modalService.open(this.createAssetModal, { size: 'lg' });
  }
  public onUploadInit(args: any): void {}

  public onUploadError(args: any): void {}

  public onUploadSuccess(args: any, type: 'edit' | 'add'): void {
    
    if (this.createOrEditAssets.files === undefined) {
      this.createOrEditAssets.files = [];
    }
    switch (type) {
      case 'edit':
        if (this.createOrEditAssets.files.length >= 1) {
        } else {
          this.createOrEditAssets.files.push(args[0]);
          if (this.createOrEditAssets.files.length === 1) {
            this.EditAssetFileElement();
          }
        }
        break;
      case 'add':
        this.createOrEditAssets.files.push(args[0]);
        
        break;
    }
  }
  public onRemoveSuccess(args: any): void {
    this.createOrEditAssets.files = this.createOrEditAssets.files.filter(
      (c) => c.name !== args.name
    );
  }

  deleteSelectedProducts(): void {}
  IsActiveAsset(asset: AssetsDto, IsActive: boolean): void {
    if (IsActive) {
      IsActive = false;
    } else {
      IsActive = true;
    }
    this.assetsService.IsActiveAsset(asset.id, IsActive).then((res) => {
      const vres = res.data.content.data;
      this.assets.filter((c) => c.id === asset.id)[0].isActive = IsActive;
      if (vres === true) {
        this.showSuccess('Successfully Submit Data');
      } else {
        this.showError('Failed to Submit Data');
      }
    });
  }

  deleteAsset(asset: AssetsDto, IsArchive: boolean): void {
    if (IsArchive) {
      IsArchive = false;
    } else {
      IsArchive = true;
    }
    this.assetsService.IsArchiveAsset(asset.id, IsArchive).then((res) => {
      const vres = res.data.content.data;
      this.assets.filter((c) => c.id === asset.id)[0].isDelete = IsArchive;
      if (vres === true) {
        this.showSuccess('Successfully Submit Data');
      } else {
        this.showError('Failed to Submit Data');
      }
    });
  }

  IsDefaultAsset(asset: AssetsDto, IsDefault: boolean): void {
    if (IsDefault) {
      IsDefault = false;
    } else {
      IsDefault = true;
    }
    this.assetsService.IsDefaultAsset(asset.id, IsDefault).then((res) => {
      const vres = res.data.content.data;
      this.assets.filter((c) => c.id === asset.id)[0].isDefaultImage =
        IsDefault;
      if (vres === true) {
        this.showSuccess('Successfully Submit Data');
      } else {
        this.showError('Failed to Submit Data');
      }
    });
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
  }
  saveAsset(): void {
    this.createOrEditAssets.elementId = this._elementId;
    this.createOrEditAssets.parentId = this._parentId;
    this.submitted = true;
    if (
      this.createOrEditAssets.files !== undefined &&
      this.createOrEditAssets.files.length !== 0
    ) {
      this.CreateOrEditAssetElement();
    } else {
      this.submitted = false;
      this.showError('Please, Add new Photo Media to Add Images to Project');
    }
  }

  findIndexById(id: string): number {
    return 1;
  }

  createId(): string {
    return 'id';
  }
  showAsset(id: number): any {
    if (id !== undefined) {
      this.assetsService.Show(id).then((res) => {
        const vres = res.data.content.data;
        this.selectedAssetForEdit = vres;
        this.createOrEditAssets.elementId = this._elementId;
        this.createOrEditAssets.parentId = this._parentId;
        this.createOrEditAssets.id = this.selectedAssetForEdit.id;
        this.createOrEditAssets.title = this.selectedAssetForEdit.title;
        this.createOrEditAssets.alt = this.selectedAssetForEdit.alt;
        this.createOrEditAssets.caption = this.selectedAssetForEdit.caption;
        this.createOrEditAssets.description =
          this.selectedAssetForEdit.description;
        this.createOrEditAssets.assetStatusId =
          this.selectedAssetForEdit.assetStatusId;
        this.createOrEditAssets.order = this.selectedAssetForEdit.order;
      });
    }
  }
  showAssetByElementId(projectId: number): void {
    this.assetsService.showAssetByElementId(projectId, 2).then((res) => {
      this.assets = res;
    });
  }
  CreateOrEditAssetElement(): void {
    
    if (this.createOrEditAssets.id === undefined) {
      this.createOrEditAssets.id = null;
    }
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.createOrEditAssets.files.length; i++) {
      const formData = new FormData();
      formData.append(
        'id',
        this.createOrEditAssets.id != null
          ? this.createOrEditAssets.id.toString()
          : null
      );
      console.log( this.createOrEditAssets.files[i]);
      formData.append('assetFile', this.createOrEditAssets.files[i]);
      formData.append(
        'elementId',
        this.createOrEditAssets.elementId.toString()
      );
      formData.append('parentId', this.createOrEditAssets.parentId.toString());

      console.log(formData);
      const asset = this.assetsService
        .CreateOrEditAssetElement(formData)
        .then((res) => {
          const assetRes = res.data.content.data;
          this.getAssetsByElementIdAndParentId(this._parentId, this._elementId);
          this.showSuccess('Project Assets Updated');
          this.asset = new AssetsDto();
          this.createOrEditAssets = new CreateOrEditAssets();
        });
    }
  }
  EditAssetFileElement(): void {
    if (this.createOrEditAssets.id === undefined) {
      this.createOrEditAssets.id = null;
    }
    // tslint:disable-next-line:prefer-for-of
    const formData = new FormData();
    formData.append('id', this.createOrEditAssets.id.toString());
    formData.append('assetFile', this.createOrEditAssets.files[0]);
    formData.append('elementId', this.createOrEditAssets.elementId.toString());
    formData.append('parentId', this.createOrEditAssets.parentId.toString());
    const asset = this.assetsService
      .EditAssetFileElement(formData)
      .then((res) => {
        const assetRes = res.data.content.data;
        const assetRess = this.showAsset(this.createOrEditAssets.id);
        this.asset = new AssetsDto();
        this.createOrEditAssets = new CreateOrEditAssets();
        this.selectedAssetForEdit = undefined;
        this.showSuccess('Project Assets Updated');
      });
  }

  EditAssetElement(): void {
    const formData = new FormData();
    formData.append(
      'id',
      this.createOrEditAssets.id != null
        ? this.createOrEditAssets.id.toString()
        : null
    );
    formData.append('title', this.createOrEditAssets.title.toString());
    formData.append('alt', this.createOrEditAssets.alt.toString());
    formData.append('caption', this.createOrEditAssets.caption.toString());
    formData.append(
      'description',
      this.createOrEditAssets.description.toString()
    );
    formData.append(
      'assetStatusId',
      this.createOrEditAssets.assetStatusId.toString()
    );
    formData.append('order', this.createOrEditAssets.order.toString());
    formData.append('elementId', this.createOrEditAssets.elementId.toString());
    formData.append('parentId', this.createOrEditAssets.parentId.toString());
    const asset = this.assetsService
      .CreateOrEditAssetElement(formData)
      .then((res) => {
        const assetRes = res.data.content.data;
        this.showAsset(this.createOrEditAssets.id);
        this.showSuccess('Project Assets Updated');
        this.asset = new AssetsDto();
        this.createOrEditAssets = new CreateOrEditAssets();
      });
  }
  showSuccess(message: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message,
      life: 3000,
    });
  }
  showError(message: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
      life: 3000,
    });
  }
}
