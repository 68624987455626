import { environment } from 'src/environments/environment';

export class ConstApi {
  static API_PORT = '8000';
  // static API_URL = 'http://localhost:8000/api/';
  static vOne = 'v1/';
  static iPanel = 'iPanel/';
  static iLogo = '';
  // Langauge
  static getAllLanguage = environment.APIUrl + 'languages';
  // Category
  static getAllCategoriesIdLanguage =
    environment.APIUrl + ConstApi.vOne + 'getAllCategoryByIdLanguage/';
  static getSubCategoryByParentIdAndIdLanguage =
    environment.APIUrl +
    ConstApi.vOne +
    'getSubCategoryByParentIdAndIdLanguage/';
  static createOrEditCategory = environment.APIUrl + 'createOrEditCategory';
  static showCategory = environment.APIUrl + 'showCategory/';
  static getAllCategory = environment.APIUrl + 'getAllCategory';
  // Project Style
  static getProjectStyleByCategoryId =
    environment.APIUrl + ConstApi.vOne + 'getProjectStyleByCategoryId/';
  static getProjectStyleByLanguageId =
    environment.APIUrl + ConstApi.vOne + 'ProjectStyleControlByLanguageId/';
  static getAllProjectStyle =
    environment.APIUrl + ConstApi.vOne + 'getAllProjectStyle';
  static createOrEditProjectStyle =
    environment.APIUrl + ConstApi.vOne + 'createOrEditProjectStyle';
  static ShowProjectStyle =
    environment.APIUrl + ConstApi.vOne + 'ShowProjectStyle/';
  // Project Type
  static ProjectTypeByLanguageId =
    environment.APIUrl + ConstApi.vOne + 'ProjectTypeByLanguageId/';
  static getProjectTypeByCategoryId =
    environment.APIUrl + ConstApi.vOne + 'getProjectTypeByCategoryId/';
  static getAllProjectType =
    environment.APIUrl + ConstApi.vOne + 'getAllProjectType';
  static createOrEditProjectType =
    environment.APIUrl + ConstApi.vOne + 'createOrEditProjectType';
  static ShowProjectType =
    environment.APIUrl + ConstApi.vOne + 'ShowProjectType/';
  // Project Purpose
  static getProjectPurposeByIdLanguage =
    environment.APIUrl + ConstApi.vOne + 'getProjectPurposeByIdLanguage/';
  static getAllProjectPurpose =
    environment.APIUrl + ConstApi.vOne + 'getAllProjectPurpose';
  static createOrEditProjectPurpose =
    environment.APIUrl + ConstApi.vOne + 'createOrEditProjectPurpose';
  static ShowProjectPurpose =
    environment.APIUrl + ConstApi.vOne + 'ShowProjectPurpose/';
  // Project Floors
  static getAllProjectFloorsByIdLanguage =
    environment.APIUrl + ConstApi.vOne + 'getProjectFloors/';
  static getAllProjectFloors =
    environment.APIUrl + ConstApi.vOne + 'getAllProjectFloors';
  static createOrEditProjectFloors =
    environment.APIUrl + ConstApi.vOne + 'createOrEditProjectFloors';
  static ShowProjectFloors =
    environment.APIUrl + ConstApi.vOne + 'ShowProjectFloors/';
  // Project Content Type
  static getAllProductContentTypeByIdLanguage =
    environment.APIUrl + ConstApi.vOne + 'productContentTypeControl/';
  static getAllProjectContentType =
    environment.APIUrl + ConstApi.vOne + 'getAllProjectContentType';
  static createOrEditProjectProjectContentType =
    environment.APIUrl +
    ConstApi.vOne +
    'createOrEditProjectProjectContentType';
  static ShowProjectProjectContentType =
    environment.APIUrl + ConstApi.vOne + 'ShowProjectProjectContentType/';
  // Permission
  static getAllActivePermission = environment.APIUrl + 'getAllActivePermission';
  // Roles
  static getAllActiveRoles = environment.APIUrl + 'getActiveRoles';
  // User
  static storeUser = environment.APIUrl + 'users';
  // Measurement Type
  static MeasurementTypeByLanguageId =
    environment.APIUrl + ConstApi.vOne + 'MeasurementTypeByLanguageId/';
  static getAllMeasurementType = environment.APIUrl + 'getAllMeasurement';
  // Tags
  static getTagsByLanguageId =
    environment.APIUrl + ConstApi.vOne + 'getTagsByLanguageId/';
  static getAllProjectTags =
    environment.APIUrl + ConstApi.vOne + 'getAllProjectTags';
  static createOrEditProjectTags =
    environment.APIUrl + ConstApi.vOne + 'createOrEditProjectTags';
  static ShowProjectTags =
    environment.APIUrl + ConstApi.vOne + 'ShowProjectTags/';
  // social Media Type
  static getSocialMediaTypeAppService =
    environment.APIUrl + ConstApi.vOne + 'getSocialMediaTypeAppService/';
  //#region Create
  // Create
  static CreateOrEditAssets = environment.APIUrl + 'createOrEditAssets';
  static CreateOrEditAssetElement =
    environment.APIUrl + ConstApi.iPanel + 'CreateOrEditAssetElement';

  //#endregion
}
// Project
export class ProjectApi {
  static Product = environment.APIUrl + 'product';
  static getAllProjects = environment.APIUrl + 'getAllAdminProjects/';
  static showProject = environment.APIUrl + 'showProject/';
  static showProjectDetail = environment.APIUrl + 'showProjectDetail/';
  static CheckProjectRef =
    environment.APIUrl + ConstApi.vOne + 'getCheckProjectRef/';
  static IsActiveProject =
    environment.APIUrl + ConstApi.vOne + 'IsActiveProject';
  static IsArchiveProject =
    environment.APIUrl + ConstApi.vOne + 'IsArchiveProject';
  static getProjectDataByProjectIdAndCategoryDataId =
    environment.APIUrl +
    ConstApi.iPanel +
    'getProjectDataByProjectIdAndCategoryDataId/';

  static CreateOrEditProject = environment.APIUrl + 'createOrEditProject';
  static CreateOrEditProjectData =
    environment.APIUrl + ConstApi.iPanel + 'CreateOrEditProjectData/';
  static GetProjectDescription =
    environment.APIUrl + ConstApi.iPanel + 'GetProjectDescription/';
  static CreateOrEditProjectDescription =
    environment.APIUrl + ConstApi.iPanel + 'CreateOrEditProjectDescription/';
}
// Offers
export class OffersApi {
  static getOfferForEdit =
    environment.APIUrl + ConstApi.iPanel + 'getOfferForEdit/';
  static getOfferForView =
    environment.APIUrl + ConstApi.iPanel + 'getOfferForView/';
  static getAllOffers = environment.APIUrl + 'getAllOffers';
  static IsActiveOffer = environment.APIUrl + 'IsActiveOffer';
  static IsArchiveOffer = environment.APIUrl + 'IsArchiveOffer';
  static CreateOrEditOffer =
    environment.APIUrl + ConstApi.iPanel + 'createOrEditOffers';
  static destroyOfferPriceRecord =
    environment.APIUrl + ConstApi.iPanel + 'destroyOfferPriceRecord';
}
// Lookup
export class LookUpApi {
  static getLookUpItem =
    environment.APIUrl + ConstApi.iPanel + 'getLookUpItem/';
  static getLookUpItemByLookupCategoryId =
    environment.APIUrl + ConstApi.iPanel + 'getLookUpItemByLookupCategoryId/';
  static getAllLookupItem =
    environment.APIUrl + ConstApi.iPanel + 'getAllLookupItem/';
  static getAllLookupItemById =
    environment.APIUrl + ConstApi.iPanel + 'getAllLookupItemById/';
  static createOrEditLookupItem =
    environment.APIUrl + ConstApi.iPanel + 'createOrEditLookupItem';
}

// Assets
export class AssetsApi {
  static IsActiveAsset = environment.APIUrl + ConstApi.iPanel + 'IsActiveAsset';
  static IsArchiveAsset =
    environment.APIUrl + ConstApi.iPanel + 'IsArchiveAsset';
  static IsDefaultAsset =
    environment.APIUrl + ConstApi.iPanel + 'IsDefaultAsset';
  static getAssetsByElementIdAndParentId =
    environment.APIUrl + ConstApi.iPanel + 'getAssetsByElementIdAndParentId/';
  static EditAssetFileElement =
    environment.APIUrl + ConstApi.iPanel + 'EditAssetFileElement';
  static ShowAsset = environment.APIUrl + ConstApi.iPanel + 'showAsset/';
  static showAssetByElementId =
    environment.APIUrl + ConstApi.iPanel + 'showAssetByElementId/';
}

// Asset Parent Type
export class AssetParentTypeApi {
  static getAssetParentTypeByName =
    environment.APIUrl + ConstApi.iPanel + 'getAssetParentTypeByName/';
}

// Authentication
export class AuthenticationApi {
  static login = environment.APIUrl + 'auth/login';
  static register = environment.APIUrl + 'auth/register';
}

// Users
export class UsersApi {
  static GetAllUsers = environment.APIUrl + ConstApi.iPanel + 'GetAllUsers';
  static CreateOrEditUsers =
    environment.APIUrl + ConstApi.iPanel + 'CreateOrEditUsers';
  static ShowUsersForEdit =
    environment.APIUrl + ConstApi.iPanel + 'ShowUsersForEdit/';
}

// User Permission
export class UserPermissionApi {
  static UserRolePermission =
    environment.APIUrl + ConstApi.iPanel + 'UserRolePermission';
}

// Roles
export class RolesApi {
  static GetAllRoles = environment.APIUrl + ConstApi.iPanel + 'GetAllRoles';
  static CreateOrEditRoles =
    environment.APIUrl + ConstApi.iPanel + 'CreateOrEditRoles';
  static ShowRolesForEdit =
    environment.APIUrl + ConstApi.iPanel + 'ShowRolesForEdit/';
}

// Permissions
export class PermissionsApi {
  static GetAllPermissions =
    environment.APIUrl + ConstApi.iPanel + 'GetAllPermissions';
  static CreateOrEditPermissions =
    environment.APIUrl + ConstApi.iPanel + 'CreateOrEditPermissions';
  static ShowPermissionsForEdit =
    environment.APIUrl + ConstApi.iPanel + 'ShowPermissionsForEdit/';
}

export class PagesApi {
  static GetAllPages = environment.APIUrl + ConstApi.iPanel + 'GetAllPages';
  static ShowPageForEdit =
    environment.APIUrl + ConstApi.iPanel + 'ShowPageForEdit/';
  static CreateOrEditPage =
    environment.APIUrl + ConstApi.iPanel + 'CreateOrEditPage';
}

export class ArticleApi {
  static GetAllArticles =
    environment.APIUrl + ConstApi.iPanel + 'GetAllArticles';
  static CreateOrEditArticle =
    environment.APIUrl + ConstApi.iPanel + 'CreateOrEditArticle';
  static ShowArticleForEdit =
    environment.APIUrl + ConstApi.iPanel + 'ShowArticleForEdit/';
}

export class FaqsApi {
  static GetAllFaqs = environment.APIUrl + ConstApi.iPanel + 'GetAllFaqs';
  static CreateOrEditFaqs =
    environment.APIUrl + ConstApi.iPanel + 'CreateOrEditFaqs';
  static ShowFaqsForEdit =
    environment.APIUrl + ConstApi.iPanel + 'ShowFaqsForEdit/';
}
