import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { SidemenuComponent } from './components/sidemenu/sidemenu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { NotificationSidebarComponent } from './components/notification-sidebar/notification-sidebar.component';

import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { FullscreenToggleDirective } from './directives/fullscreen-toggle.directive';
import { MessageLayoutComponent } from './layouts/message-layout/message-layout.component';
import { ErrorLayoutComponent } from './layouts/error-layout/error-layout.component';
import { SearchDatePipe } from './pipes/search-date.pipe';
import { HoriFullLayoutComponent } from './layouts-horizontal/hori-full-layout/hori-full-layout.component';
import { HoriHeaderComponent } from './components-horizontal/hori-header/hori-header.component';
import { HorizontalMenuComponent } from './components-horizontal/horizontal-menu/horizontal-menu.component';
import { WorkFlowActionComponent } from './common/work-flow-action/work-flow-action.component';
import { ColumnFunctionComponent } from './common/column-function/column-function.component';
import { FilterComponent } from './common/Filter/filter/filter.component';
import { TableModule } from 'primeng/table';
import { LookupWidgetComponent } from './common/widget/lookup-widget/lookup-widget.component';
import { CreateOrEditLookupComponent } from './common/widget/lookup-widget/create-or-edit-lookup/create-or-edit-lookup.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'angular-calendar';
import { ButtonModule } from 'primeng/button';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { SliderModule } from 'primeng/slider';
import { TagModule } from 'primeng/tag';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { AssetWidgetComponent } from './common/widget/asset-widget/asset-widget.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { AssetModalComponent } from './common/widget/asset-widget/asset-modal/asset-modal.component';
import { DropzoneConfigInterface, DropzoneModule, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { CktextEditorComponent } from './common/cktext-editor/cktext-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  //  url: environment.APIUrl +x 'v1/DraftAssets',
   acceptedFiles: 'image/*'
 };
@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    TapToTopComponent,
    SidemenuComponent,
    NotificationSidebarComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    FullscreenToggleDirective,
    MessageLayoutComponent,
    ErrorLayoutComponent,
    SearchDatePipe,
    HoriFullLayoutComponent,
    HoriHeaderComponent,
    HorizontalMenuComponent,
    WorkFlowActionComponent,
    ColumnFunctionComponent,
    FilterComponent,
    LookupWidgetComponent,
    CreateOrEditLookupComponent,
    AssetWidgetComponent,
    AssetModalComponent,
    CktextEditorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    /** PrimeNG */
    TableModule,
    ToastModule,
    CalendarModule,
    SliderModule,
    MultiSelectModule,
    ContextMenuModule,
    DialogModule,
    ButtonModule,
    DropdownModule,
    ProgressBarModule,
    InputTextModule,
    InputSwitchModule,
    ToolbarModule,
    MessagesModule,
    MessageModule,
    TooltipModule,
    TagModule,
    ConfirmDialogModule,
    DropzoneModule,
    NgxDropzoneModule,
    CKEditorModule
  ],
  exports: [
    LoaderComponent,
    TapToTopComponent,
    FooterComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    SearchDatePipe,
    FilterComponent,
    LookupWidgetComponent,
    CreateOrEditLookupComponent,
    AssetWidgetComponent,
    CKEditorModule,
    CktextEditorComponent,
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
