import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LazyLoadEvent, PrimeNGConfig } from 'primeng/api';
import { GetAllLookupItemDto, LookupCategoriesEnum } from 'src/app/models/Lookup/lookup-item-dto';
import { LookupItemsService } from 'src/app/services/Lookup/lookup-items.service';

@Component({
  selector: 'app-lookup-widget',
  templateUrl: './lookup-widget.component.html',
  styleUrls: ['./lookup-widget.component.scss']
})
export class LookupWidgetComponent implements OnInit {
  @Input() routeLink: string;
  lookupItems: GetAllLookupItemDto[] = [];
  loading!: boolean | true;
  headerColumns!: any[];
  totalRecords!: number | 0;
  LookupItemId: number;
  // tslint:disable-next-line:variable-name
  _lookupCategory!: LookupCategoriesEnum;
  get LookupCategory(): LookupCategoriesEnum {
    return this._lookupCategory;
  }
  @Input() set LookupCategory(value: LookupCategoriesEnum) {
    if (value !== undefined){
      this._lookupCategory = value;
      this.GetAllProjectType();
    }
  }
  constructor(
    private primengConfig: PrimeNGConfig,
    private lookupItemsService: LookupItemsService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.headerColumns = [
      { field: 'Category', header: 'Category' },
      { field: 'TitleEn', header: 'English Title' },
      { field: 'TitleAr', header: 'Arabic Title' },
      { field: 'user', header: 'user' },
      { field: 'isActive', header: 'isActive' },
      { field: 'isDelete', header: 'isDelete' },
      { field: 'createdAt', header: 'Created At' },
      { field: 'updatedAt', header: 'last Update' },
      { field: 'deletedAt', header: 'Deleted At' },
    ];
  }
  GetAllProjectType(event?: LazyLoadEvent): void {
    this.loading = false;
    this.primengConfig.ripple = false;
    this.lookupItemsService.getAllLookupItem(this._lookupCategory).then((res) => {
      console.log(res);
      this.lookupItems = res.data.content.data;
    });
  }
  edit(id: any, largeDescmodal: any): void {
    //console.log(id);
    this.LookupItemId = id;
    //this.router.navigate([this.routeLink, id]);
    this.modalService.open(largeDescmodal, { size: 'lg' });
  }
  close():void{
    this.modalService.dismissAll();
    this.GetAllProjectType();
  }

}
