import { Routes, RouterModule} from '@angular/router';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...
// tslint:disable-next-line:variable-name
export const Content_Routes: Routes = [

];

// tslint:disable-next-line:variable-name
export const Message_Routes: Routes = [

];

